<template>
  <div class="mt-2">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-card header="Dati Identificativi" header-tag="header">
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-radio
                        name="tipo"
                        label="Seleziona Tipo"
                        v-model="salesman_type"
                        :options="salesman_types"
                      />
                    </div>
                    <div v-if="isPhisical" class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].attribute_SURN_value.label"
                        vid="attribute_SURN_value"
                        :label="beForm[rep].attribute_SURN_value.label"
                        v-model="form[rep].attribute_SURN_value"
                        placeholder="Inserisci un cognome"
                        :rules="isPhisical ? { required: true } : {}"
                      />
                    </div>
                    <div v-if="isPhisical" class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].attribute_NAME_value.label"
                        vid="attribute_NAME_value"
                        :label="beForm[rep].attribute_NAME_value.label"
                        v-model="form[rep].attribute_NAME_value"
                        placeholder="Inserisci un nome"
                        :rules="isPhisical ? { required: true } : {}"
                      />
                    </div>
                    <div v-if="!isPhisical" class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].attribute_CNAM_value.label"
                        vid="attribute_CNAM_value"
                        :label="beForm[rep].attribute_CNAM_value.label"
                        v-model="form[rep].attribute_CNAM_value"
                        placeholder="Inserisci una ragione sociale"
                        :rules="!isPhisical ? { required: true } : {}"
                      />
                    </div>
                    <div v-if="!isPhisical" class="form-group col-md-3">
                      <base-input
                        name="p_iva"
                        vid="attribute_CREG_value"
                        label="Partita IVA"
                        v-model="form[rep].attribute_CREG_value"
                        placeholder="Inserisci una partita IVA"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].attribute_NINO_value.label"
                        vid="attribute_NINO_value"
                        :label="beForm[rep].attribute_NINO_value.label"
                        v-model="form[rep].attribute_NINO_value"
                        placeholder="Inserisci un codice fiscale"
                        textcase="upper"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="attribute_CLI_value"
                        :name="beForm[rep].attribute_CLI_value.label"
                        :label="beForm[rep].attribute_CLI_value.label"
                        v-model="form[rep].attribute_CLI_value"
                        :options="
                          Object.values(
                            beForm[rep].attribute_CLI_value.options
                          ).map((o) => {
                            return { value: o.value, text: o.text };
                          })
                        "
                        :rules="{ required: true }"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="attribute_SUP_value"
                        :name="beForm[rep].attribute_SUP_value.label"
                        :label="beForm[rep].attribute_SUP_value.label"
                        v-model="form[rep].attribute_SUP_value"
                        :options="
                          Object.values(
                            beForm[rep].attribute_SUP_value.options
                          ).map((o) => {
                            return { value: o.value, text: o.text };
                          })
                        "
                        :rules="{ required: true }"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <address-book-form
                v-model="address_book_form"
                :beECForm="beForm[addrBook]"
                :repository="addrBook"
              ></address-book-form>
              <correspondence-form
                v-model="correspondence_form"
                :beECForm="beForm[cor]"
                :repository="cor"
              >
              </correspondence-form>
              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
                <b-button @click="resetForm()" variant="lisaweb" size="sm"
                  >Reset</b-button
                >
                <b-button
                  @click="$router.back()"
                  variant="lisaweb"
                  size="sm"
                  class="float-right"
                  ><b-icon-chevron-double-left
                    font-scale="0.9"
                  ></b-icon-chevron-double-left
                  >Torna indietro</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
import BaseRadio from "@/components/form/BaseRadio";
import AddressBookForm from "@/components/form/AddressBookForm";
import CorrespondenceForm from "@/components/form/CorrespondenceForm";
import AddressBookCorrespondenceMixin from "@/mixins/AddressBookCorrespondenceMixin";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import FormMixin from "@/mixins/FormMixin";
import { mapGetters } from "vuex";

export default {
  mixins: [FormMixin, ShortcutMixin, AddressBookCorrespondenceMixin],
  data() {
    return {
      repository: "agency_contact",
      id: this.$route.params.id,
      form: {
        defaultCountryName: "Italia",
        home_province: null,
        optHomeCountries: [],
        optHomeProvinces: [],
        optHomeCouncils: [],
        agency_contact: {
          status_agencycontact: 0,
          attribute_NAME_value: null,
          attribute_SURN_value: null,
          attribute_CNAM_value: null,
          attribute_CREG_value: null,
          attribute_NINO_value: null,
          attribute_CLI_value: null,
          attribute_SUP_value: null,
        },
        address_book: {
          status_addressbook: 0,
          attribute_CELL_value: null,
          attribute_FAX_value: null,
          attribute_MAIL_value: null,
          attribute_PEC_value: null,
          attribute_PHON_value: null,
          attribute_WEB_value: null,
          agency_contact: {},
        },
        correspondence: {
          status_correspondence: 0,
          attribute_ADDR_value: null,
          attribute_CITY_value: null,
          attribute_CITY2_value: null,
          attribute_NOR_value: null,
          attribute_NAME2_value: null,
          attribute_ZIPC_value: null,
          agency_contact: {},
        },
      },
      salesman_type: 0,
      salesman_types: [
        { value: 0, text: "Persona Fisica" },
        { value: 1, text: "Persona Giuridica" },
      ],
    };
  },
  components: {
    BaseIcon,
    BaseInput,
    BaseRadio,
    AddressBookForm,
    CorrespondenceForm,
    BaseSelect,
  },
  methods: {
    onSubmit() {
      switch (this.isPhisical) {
        case true:
          this.$delete(this.form[this.repository], "attribute_CREG_value");
          this.$delete(this.form[this.repository], "attribute_CNAM_value");
          break;
        case false:
          this.$delete(this.form[this.repository], "attribute_SURN_value");
          this.$delete(this.form[this.repository], "attribute_NAME_value");
          break;
      }
      this.isLoading = true;
      this.store(this.repository)
        .then((response) => {
          let id = response.data.data.id;
          let promises = [];
          promises.push(this.storeSection(this.addrBook, id, this.repository));
          promises.push(this.storeSection(this.cor, id, this.repository));
          Promise.all(promises)
            .then(() => {
              this.onSuccess("contatto");
              this.shortcut(
                "module.PFOLIO",
                null,
                "#AgencyContacts",
                "filterAgencyContact"
              );
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: `${errMsg}`,
              });
            })
            .finally(() => {
              this.isLoading = false;
            });
        })
        .catch((error) => {
          this.onFailure(error);
        });
    },
    onSuccess(resource) {
      this.$showSnackbar({
        preset: "success",
        text: `Azione completata: ${resource} creato`,
      });
    },
    onFailure(error) {
      let errMsg = this.$getErrorMessage(error);
      this.$showSnackbar({
        preset: "error",
        text: `${errMsg}`,
      });
      this.isLoading = false;
    },
    ...mapGetters("auth", [
      "countries",
      "provinces",
      "provinceByProvinceId",
      "countries",
      "provinces",
      "councils",
    ]),
  },
  beforeMount() {
    this.initCreateCorrespondencesForm();
  },
  created() {
    this.isLoading = true;
    const sl = this.fetchCreateForm(this.repository);
    const cr = this.fetchCreateForm(this.cor);
    const ad = this.fetchCreateForm(this.addrBook);
    Promise.all([cr, sl, ad]).then(() => {
      this.isLoading = false;
    });
  },
  computed: {
    rep() {
      return this.repository;
    },
    cor() {
      return "correspondence";
    },
    addrBook() {
      return "address_book";
    },
    isPhisical() {
      return this.salesman_type === 0;
    },
  },
};
</script>
